<template>
  <v-list>
    <v-list-group
      v-for="(item, index) of campaignData"
      :key="index"
      class="pa-0 mx-0 mt-3"
    >
      <template v-slot:activator>
        <v-list-item-title class="item-title">
          <p class="pb-0 mb-0">{{ item.name }}</p>
          <small>457-244-12 Pending</small>
        </v-list-item-title>
        <br />
      </template>
      <v-list-item class="d-flex align-center item py-0 my-0">
        <span class="name">Clicks</span>
        <v-spacer></v-spacer>
        <span>{{ item.clicks }}</span>
      </v-list-item>
      <v-list-item class="d-flex align-center item py-0 my-0">
        <span class="name">CTR</span>
        <v-spacer></v-spacer>
        <span>{{ item.ctr }}</span>
      </v-list-item>
      <v-list-item class="d-flex align-center item py-0 my-0">
        <span class="name">Conversions</span>
        <v-spacer></v-spacer>
        <span>{{ item.conversions }}</span>
      </v-list-item>
      <v-list-item class="d-flex align-center item py-0 my-0">
        <span class="name">Cost/Conversions</span>
        <v-spacer></v-spacer>
        <span>{{ item.cost_conversions }}</span>
      </v-list-item>
      <v-list-item class="d-flex align-center item py-0 my-0">
        <span class="name">Cost</span>
        <v-spacer></v-spacer>
        <span
          ><strong>US${{ item.cost }}</strong></span
        >
      </v-list-item>
      <v-list-item class="d-flex align-center item py-0 my-0">
        <span class="name">Status</span>
        <v-spacer></v-spacer>
        <span>
          <v-chip :class="item.status" class="chip font-weight-bold">
            <v-icon
              v-if="item.status === 'pending'"
              class="mr-1"
              color="#828282"
              small
              >mdi-circle-outline
            </v-icon>
            <v-icon
              v-if="item.status === 'active'"
              class="mr-1"
              color="#0057FF"
              small
              >mdi-check</v-icon
            >
            <v-icon
              v-if="item.status === 'paused'"
              class="mr-1"
              color="#F2994A"
              small
              >mdi-pause</v-icon
            >
            <span class="mt-1">
              {{ item.status }}
            </span>
          </v-chip>
        </span>
      </v-list-item>
      <v-list-item class="d-flex align-center item py-0 my-0 mb-2">
<!--        <span class="name">Showing product detail</span>-->
<!--        <v-spacer></v-spacer>-->
        <span>
          <v-btn
            @click="handleClickDetails($event, item.id)"
            class="button"
            height="50"
            v-on="on"
          >
          <span
            style="font-size: 14px"
            class="ml-2 font-family-raleway-medium"
          >Deatail</span
          >
        </v-btn>
        </span>

      </v-list-item>
      <v-divider class="mx-1"></v-divider>
    </v-list-group>
  </v-list>
</template>
<script>
export default {
  name: "ShowInMobileTable",
  props: {
    campaignData: Array,
  },
  data: () => ({
    headers: [
      {
        text: "Campaigns",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Clicks", value: "clicks" },
      { text: "CTR", value: "ctr" },
      { text: "Conversions", value: "conversions" },
      { text: "Cost/Conversions", value: "cost_conversions" },
      { text: "Cost", value: "cost" },
      { text: "Status", value: "status" },
    ],
  }),
  methods : {
    handleClickDetails(e, val) {
      e.stopPropagation();
      this.$router.push(`my-campaigns-detail/${val}`);
    },
  }
};
</script>
<style lang="scss" scoped>
.chip {
  height: 32px;
  border-radius: 10px;
  padding: 0 15px !important;
  text-transform: uppercase;
}

.item {
  .name {
    color: #828282;
  }
}

.item-title {
  p {
    color: #0057ff !important;
    font-size: 18px;
  }

  small {
    font-size: 15px;
    color: #828282;
  }
}

.button{
  border-radius: 16px!important;
  padding: 0 16px!important;
  min-width: 48px!important;
  height: 48px!important;
}
</style>
